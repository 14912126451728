fieldset {
  border: 0;
  margin: 0;
  min-width: 0;
  padding: 0;
}

legend {
  border: 0;
  color: inherit;
  display: block;
  font-size: 120%;
  font-weight: bold;
  line-height: inherit;
  margin: 0;
  padding: 0;
}

/* @import url("https://maxcdn.bootstrapcdn.com/bootstrap/3.3.6/css/bootstrap.css"); */

.eform-container {
  margin-bottom: 32px;
}

.eform-container > .ui.header {
  border-bottom: solid #ccc 1px;
  margin-top: 40px;
  padding-bottom: 16px;
}

.eform-container > .ui.header h1 {
  color: #333;
  margin-top: -2px;
}

form.rjsf {
  margin-top: 8px;
}

form.rjsf > div:last-child {
  text-align: right;
}

form.rjsf > div:last-child button[type="submit"] {
  font-size: 116%;
  width: 100%;
}

form.rjsf fieldset,
form.rjsf > div + div {
  margin-top: 16px;
}

form.rjsf fieldset fieldset {
  padding: 0 6px 0 6px;
}

.eform-container form.rjsf fieldset fieldset {
  border: solid 1px #888;
  border-radius: 4px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  padding: 0 16px 16px 16px;
  -webkit-transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
}

form.rjsf fieldset > legend {
  background-color: #ffff;
  margin-left: -4px;
  padding: 0 4px;
}

.form-group {
  margin: 6px 0;
}

.form-group #root__title {
  font-size: 150%;
  font-weight: 500;
}

.form-group #root__title + p {
  margin-top: 6px;
}

.form-group legend,
.form-group label[for="root_attachments"] {
  font-size: 120%;
}

.form-group label[for="root_attachments"] {
  margin-top: 16px;
  font-weight: 700;
}

.form-group legend + div,
.form-group p input[type="file"] {
  margin-top: 12px;
}

.form-group .control-label {
  display: block;
  padding: 2px;
}

.form-group label + p {
  color: #666;
  font-size: 90%;
  margin-bottom: 2px;
  padding-left: 2px;
}

.form-group + .form-group.field-null {
  padding-top: 8px;
}

.form-group.field-null label {
  font-weight: 700;
}

.form-group.field-null label + p {
  color: #000;
  font-size: 100%;
}

.form-control {
  display: block;
  width: 100%;
  height: 34px;
  padding: 6px 12px;
  font-size: 14px;
  line-height: 1.42857143;
  color: #555;
  background-color: #fff;
  background-image: none;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  -webkit-transition: border-color ease-in-out 0.15s,
    -webkit-box-shadow ease-in-out 0.15s;
  -webkit-transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
  transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
}
.form-control:focus {
  border-color: #66afe9;
  outline: 0;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075),
    0 0 8px rgba(102, 175, 233, 0.6);
}
.form-control::-moz-placeholder {
  color: #999;
  opacity: 1;
}
.form-control:-ms-input-placeholder {
  color: #999;
}
.form-control::-webkit-input-placeholder {
  color: #999;
}
.form-control::-ms-expand {
  background-color: transparent;
  border: 0;
}
.form-control[disabled],
.form-control[readonly],
fieldset[disabled] .form-control {
  background-color: #eee;
  opacity: 1;
}
.form-control[disabled],
fieldset[disabled] .form-control {
  cursor: not-allowed;
}
textarea.form-control {
  height: auto;
}
input[type="search"] {
  -webkit-appearance: none;
}
select.form-control[multiple] {
  height: auto;
}

.alert,
.ui.error.message {
  padding: 15px;
  margin-bottom: 20px;
  border: 1px solid #a94442;
  border-radius: 4px;
  box-shadow: none;
}

.alert ul {
  list-style-type: decimal;
  margin: 0;
  padding-left: 16px;
}

ul.error-detail {
  font-size: 90%;
  list-style-type: none;
  margin-top: 2px;
  padding-left: 2px;
}

.alert-danger li,
.alert-heading,
.form-group .required,
.text-danger,
.ui.error.message {
  color: #912d2b;
  opacity: 1;
}

.btn {
  display: inline-block;
  padding: 6px 12px;
  margin-bottom: 0;
  font-size: 14px;
  font-weight: normal;
  line-height: 1.42857143;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  touch-action: manipulation;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-image: none;
  background-color: #337ab7;
  border: 1px solid #2e6da4;
  border-radius: 2px;
  color: #fff;
}
.btn:focus,
.btn:active:focus,
.btn.active:focus,
.btn.focus,
.btn:active.focus,
.btn.active.focus {
  outline: thin dotted;
  outline: 5px auto -webkit-focus-ring-color;
  outline-offset: -2px;
}
.btn:hover,
.btn:focus,
.btn.focus {
  background-color: #286090;
  border-color: #122b40;
  text-decoration: none;
}
.btn:active,
.btn.active {
  background-image: none;
  outline: 0;
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
}
.btn.disabled,
.btn[disabled],
fieldset[disabled] .btn {
  cursor: not-allowed;
  filter: alpha(opacity=65);
  box-shadow: none;
  opacity: 0.65;
}

span.required {
  margin-left: 4px;
}

.field-radio-group {
  padding-bottom: 4px;
  padding-top: 4px;
}

.field-radio-group div {
  margin-top: 4px;
  padding-left: 4px;
}

.form-group input[type="checkbox"] {
  margin: 16px 8px 12px 4px;
}

.form-group input[type="checkbox"],
.field-radio-group input[type="radio"] {
  -webkit-transform: scale(1.5);
          transform: scale(1.5);
}

.form-group input[type="checkbox"] + span,
.field-radio-group input[type="radio"] + span {
  padding-left: 8px;
}

textarea.form-control {
  min-height: 80px;
}

body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}

h1,
h2,
h3,
h4,
h5 {
  line-height: normal;
  line-height: initial;
}

h2 {
  color: #666;
  text-transform: uppercase;
}

h3,
h4,
hr {
  margin-top: 36px;
}
hr {
  background-color: rgba(34, 36, 38, 0.15);
  border: none;
  height: 1px;
}

p {
  line-height: 150%;
}

.clear-top {
  margin-top: 0 !important;
  padding-top: 0 !important;
}

.box-header,
.ui.inverted.menu {
  background-color: #0067b8;
}

.ui.dimmer {
  background-color: rgba(0, 0, 0, 0.57);
}

.link {
  color: #4183c4 !important;
  text-decoration: none;
  background: none !important;
  background-color: transparent;
  color: inherit;
  border: none;
  padding: 0 !important;
  font: inherit;
  cursor: pointer;
}

.ui.inverted.menu .right.menu,
.ui.vertical.menu > .item:hover {
  background-color: #666 !important;
  color: #fff !important;
}

.ui.vertical.menu,
.ui.vertical.menu .header,
.ui.vertical.menu .item {
  background-color: #e3e3e3;
  color: #666 !important;
}

.ui.vertical.menu > .active.item {
  background-color: #d4d4d4;
  color: #333 !important;
}

.box {
  border: solid 1px #0067b8;
  border-radius: 4px;
  box-shadow: 2px 2px #ccc;
}
.box-body {
  padding: 24px 32px;
}
.box-header {
  color: #fff;
  padding: 12px 16px;
}

code,
.code {
  border-radius: 4px;
  border: solid 1px #666;
  background-color: beige;
  color: #333;
  display: block;
  padding: 8px 12px;
  width: 99%;
  white-space: pre-wrap;
  white-space: -moz-pre-wrap !important;
  white-space: -pre-wrap;
  white-space: -o-pre-wrap;
  word-wrap: break-word;
}

.contain:after {
  visibility: hidden;
  display: block;
  font-size: 0;
  content: " ";
  clear: both;
  height: 0;
}
.contain {
  display: inline-block;
}
* html .contain {
  height: 1%;
}
.contain {
  display: block;
}

ul.item-list,
ul.tile-list {
  list-style: none;
  margin: 0;
  padding: 0;
}

ul.item-list li,
ul.tile-list li {
  cursor: pointer;
}

ul.tile-list > li {
  background-color: #888;
  border: solid 1px #666;
  border-radius: 8px;
  color: #fff;
  display: block;
  float: left;
  margin: 0;
  margin-top: 16px;
  min-height: 50px;
  min-width: 160px;
  padding: 18px 14px 12px 14px;
  white-space: nowrap;
}
ul.tile-list > li + li {
  margin-left: 16px;
}

ul.tile-list li.tile-item span.tile-caption {
  display: block;
  font-weight: 500;
}

ul.tile-list li.tile-item .tile-count {
  display: block;
  font-size: 240%;
  padding-bottom: 12px;
}

ul.item-list {
  padding-right: 12px;
  white-space: nowrap;
  overflow-x: hidden;
  text-overflow: ellipsis;
}

ul.item-list li + li {
  margin-top: 8px;
}

ul.item-list .tile-count {
  text-align: center;
  padding-top: 2px;
  margin-right: 12px;
  color: #666;
  font-weight: 700;
  height: 24px;
  width: 24px;
  background-color: #ddd;
  border-radius: 50%;
  display: inline-block;
}

ul.tile-list > li.failure {
  border-color: rgb(211, 9, 9);
  background-color: rgb(211, 9, 9, 0.85);
}

ul.tile-list > li.invalid {
  border-color: rgb(240, 101, 9);
  background-color: rgba(240, 101, 9, 0.85);
}

ul.tile-list > li.waiting {
  border-color: rgb(240, 148, 9);
  background-color: rgba(240, 148, 9, 0.85);
}

ul.tile-list > li.success {
  border-color: rgb(58, 131, 10);
  background-color: rgb(58, 131, 10, 0.85);
}

.nowrap {
  white-space: nowrap;
}

#root {
  position: relative;
  height: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
}

.fixfloat:after {
  content: "";
  display: table;
  clear: both;
}

.hero {
  background-image: url("https://www.roiltd.co.uk/wp-content/uploads/2015/06/web-slide1.3.jpg") !important;
  background-repeat: no-repeat !important;
  background-position: right top !important;
  background-size: cover !important;
  color: #f8f8f8;
  padding: 20px 0px !important;
  margin: 0px !important;
}

.segment .container p {
  line-height: 1.8;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
  to {
    -webkit-transform: scale(1.08);
            transform: scale(1.08);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
  to {
    -webkit-transform: scale(1.08);
            transform: scale(1.08);
  }
}

