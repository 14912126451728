@import url(./app-normalise.css);
@import url(./app-form.css);

body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}

h1,
h2,
h3,
h4,
h5 {
  line-height: initial;
}

h2 {
  color: #666;
  text-transform: uppercase;
}

h3,
h4,
hr {
  margin-top: 36px;
}
hr {
  background-color: rgba(34, 36, 38, 0.15);
  border: none;
  height: 1px;
}

p {
  line-height: 150%;
}

.clear-top {
  margin-top: 0 !important;
  padding-top: 0 !important;
}

.box-header,
.ui.inverted.menu {
  background-color: #0067b8;
}

.ui.dimmer {
  background-color: rgba(0, 0, 0, 0.57);
}

.link {
  color: #4183c4 !important;
  text-decoration: none;
  background: none !important;
  background-color: transparent;
  color: inherit;
  border: none;
  padding: 0 !important;
  font: inherit;
  cursor: pointer;
}

.ui.inverted.menu .right.menu,
.ui.vertical.menu > .item:hover {
  background-color: #666 !important;
  color: #fff !important;
}

.ui.vertical.menu,
.ui.vertical.menu .header,
.ui.vertical.menu .item {
  background-color: #e3e3e3;
  color: #666 !important;
}

.ui.vertical.menu > .active.item {
  background-color: #d4d4d4;
  color: #333 !important;
}

.box {
  border: solid 1px #0067b8;
  border-radius: 4px;
  box-shadow: 2px 2px #ccc;
}
.box-body {
  padding: 24px 32px;
}
.box-header {
  color: #fff;
  padding: 12px 16px;
}

code,
.code {
  border-radius: 4px;
  border: solid 1px #666;
  background-color: beige;
  color: #333;
  display: block;
  padding: 8px 12px;
  width: 99%;
  white-space: pre-wrap;
  white-space: -moz-pre-wrap !important;
  white-space: -pre-wrap;
  white-space: -o-pre-wrap;
  word-wrap: break-word;
}

.contain:after {
  visibility: hidden;
  display: block;
  font-size: 0;
  content: " ";
  clear: both;
  height: 0;
}
.contain {
  display: inline-block;
}
* html .contain {
  height: 1%;
}
.contain {
  display: block;
}

ul.item-list,
ul.tile-list {
  list-style: none;
  margin: 0;
  padding: 0;
}

ul.item-list li,
ul.tile-list li {
  cursor: pointer;
}

ul.tile-list > li {
  background-color: #888;
  border: solid 1px #666;
  border-radius: 8px;
  color: #fff;
  display: block;
  float: left;
  margin: 0;
  margin-top: 16px;
  min-height: 50px;
  min-width: 160px;
  padding: 18px 14px 12px 14px;
  white-space: nowrap;
}
ul.tile-list > li + li {
  margin-left: 16px;
}

ul.tile-list li.tile-item span.tile-caption {
  display: block;
  font-weight: 500;
}

ul.tile-list li.tile-item .tile-count {
  display: block;
  font-size: 240%;
  padding-bottom: 12px;
}

ul.item-list {
  padding-right: 12px;
  white-space: nowrap;
  overflow-x: hidden;
  text-overflow: ellipsis;
}

ul.item-list li + li {
  margin-top: 8px;
}

ul.item-list .tile-count {
  text-align: center;
  padding-top: 2px;
  margin-right: 12px;
  color: #666;
  font-weight: 700;
  height: 24px;
  width: 24px;
  background-color: #ddd;
  border-radius: 50%;
  display: inline-block;
}

ul.tile-list > li.failure {
  border-color: rgb(211, 9, 9);
  background-color: rgb(211, 9, 9, 0.85);
}

ul.tile-list > li.invalid {
  border-color: rgb(240, 101, 9);
  background-color: rgba(240, 101, 9, 0.85);
}

ul.tile-list > li.waiting {
  border-color: rgb(240, 148, 9);
  background-color: rgba(240, 148, 9, 0.85);
}

ul.tile-list > li.success {
  border-color: rgb(58, 131, 10);
  background-color: rgb(58, 131, 10, 0.85);
}

.nowrap {
  white-space: nowrap;
}

#root {
  position: relative;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.fixfloat:after {
  content: "";
  display: table;
  clear: both;
}

.hero {
  background-image: url("https://www.roiltd.co.uk/wp-content/uploads/2015/06/web-slide1.3.jpg") !important;
  background-repeat: no-repeat !important;
  background-position: right top !important;
  background-size: cover !important;
  color: #f8f8f8;
  padding: 20px 0px !important;
  margin: 0px !important;
}

.segment .container p {
  line-height: 1.8;
}

@keyframes App-logo-spin {
  from {
    transform: scale(1);
  }
  to {
    transform: scale(1.08);
  }
}
