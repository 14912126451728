fieldset {
  border: 0;
  margin: 0;
  min-width: 0;
  padding: 0;
}

legend {
  border: 0;
  color: inherit;
  display: block;
  font-size: 120%;
  font-weight: bold;
  line-height: inherit;
  margin: 0;
  padding: 0;
}
